<template>
  <div class="ui container">
    <div class="ui menu">
      <menu-item
        v-if="!editing"
        :action="edit"
        classes="primary"
        icon="edit"
        label="Edit"
      ></menu-item>

      <menu-item v-if="!editing && $route.params.id !== 'new' && !ink.is_active"
        :action="enable"
        :loading="loading.enable"
        classes="positive basic"
        icon="add"
        label="Enable"
      ></menu-item>

      <menu-item v-if="!editing && $route.params.id !== 'new' && ink.is_active"
        :action="disable"
        :loading="loading.disable"
        classes="negative basic"
        icon="remove"
        label="Disable"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="submit"
        classes="primary"
        icon="mail"
        label="Submit"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="discard"
        classes="secondary basic"
        icon="undo"
        label="Discard"
      ></menu-item>
    </div>

    <div class="ui raised segment">
      <div class="ui text container">
        <div
          class="ui active centered inline loader"
          v-if="loading.ink"
        ></div>

        <h1 v-if="$route.params.id === 'new'">New Ink</h1>
        <h1 v-else-if="!editing">{{ ink.name }} <span v-if="!ink.is_active">(Disabled)</span></h1>
        <div id="inkForm" class="ui equal width form">
          <div class="ui error message"></div>
          <div class="field">
            <label for="name">Name</label>
            <input
              v-if="editing"
              v-model="ink.name"
              id="name"
              name="name"
            />
            <p v-else>{{ ink.name }}</p>
          </div>
          <div class="field">
            <label for="pms">PMS</label>
            <input
              v-if="editing"
              v-model="ink.PMS"
              id="pms"
              name="pms"
            />
            <p v-else>{{ ink.PMS }}</p>
          </div>
          <div class="field">
            <label for="pms">Color</label>
            <input
              v-if="editing"
              v-model="ink.RGB"
              type="color"
              id="color"
              name="color"
            />
            <p v-else>
              <span class="ui label" :style="`background-color: ${ink.RGB}; border-color: ${ink.RGB};`" />
              {{ ink.RGB }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import formErrorHandler from "@/utils/formErrorHandler.js";
import { mapState } from "vuex";

const $ = window.jQuery;

export default {
  components: {
    MenuItem
  },

  data() {
    return {
      editing: false,
      ink: {},
      loading: {
        ink: false,
        disable: false,
        enable: false
      }
    };
  },

  computed: {
    isLoading() {
      return Object.keys(this.loading).reduce(
        (acc, v) => acc || this.loading[v],
        false
      );
    },

    ...mapState(["currentBusinessId"])
  },

  methods: {
    edit() {
      this.editing = true;
    },

    discard() {
      if (window.confirm("Discard all changes?")) {
        if (this.$route.params.id === "new") {
          this.$router.push({ name: "Inks" });
        } else {
          window.location.reload();
        }
      }
    },

    enable () {
      if (window.confirm("Are you sure you want to enable this ink?")) {
        this.loading.enable = true
        this.$http.patch('api/v1/inks/' + this.ink.id + '/enable/').then(() => {
          window.location.reload();
        }).catch((error) => {
          this.loading.enable = false
          formErrorHandler("inkForm", error);
        })
      }
    },

    disable () {
      if (window.confirm("Are you sure you want to disable this ink?")) {
        this.loading.disable = true
        this.$http.patch('api/v1/inks/' + this.ink.id + '/disable/').then(() => {
          window.location.reload();
        }).catch((error) => {
          this.loading.disable = false
          formErrorHandler("inkForm", error);
        })
      }
    },

    async submit() {
      $(".field.error").removeClass("error");
      if (!$("#inkForm").form("validate form")) {
        return;
      }

      if (this.$route.params.id === "new") {
        const payload = {
          business: this.currentBusinessId,
          ...this.ink
        };

        try {
          await this.$http.post("api/v1/inks/", payload);
          this.$router.push({ name: "Inks" });
        } catch (error) {
          formErrorHandler("inkForm", error);
        }
      } else {
        try {
          await this.$http.patch(
            `api/v1/inks/${this.ink.id}/`,
            this.ink
          );
          this.$router.push({ name: "Inks" });
        } catch (error) {
          formErrorHandler("inkForm", error);
        }
      }
    }
  },

  watch: {
    editing(editing) {
      if (editing) {
        this.$nextTick(() => {
          $("#inkForm").form({
            fields: {
              name: "empty"
            }
          });
        });
      }
    }
  },

  async created() {
    if (this.$route.params.id !== "new") {
      this.editing = false;
      this.loading.ink = true;
      let inkResponse = null;
      try {
        inkResponse = await this.$http.get(
          `api/v1/inks/${this.$route.params.id}/`
        );

        this.ink = inkResponse.data;
      } catch (error) {
        alert(error);
      } finally {
        this.loading.ink = false;
      }
    } else {
      this.editing = true;
    }
  }
};
</script>
